<template>
  <div class="mt-0 mt-lg-2">
    <b-row class="flex-column-reverse flex-lg-row">
      <b-col
        xs="12"
        md="12"
        lg="8"
      >
        <div class="d-flex align-items-start mb-1 flex-wrap">
          <div
            v-for="(step, s) in steps"
            :key="s"
            class="d-flex align-items-center mr-2 mb-2"
            :class="{ 'cursor-pointer': step.step < active }"
            @click="changeStep(step.step)"
          >
            <feather-icon
              v-if="s > 0"
              icon="ChevronRightIcon"
              :class="{ 'text-primary': step.step === active, 'text-secondary text-lighten-5': step.step !== active }"
              class="mr-1"
              size="15"
            />
            <div
              :class="{ 'bg-primary': step.step === active, 'bg-primary bg-lighten-5': step.step < active, 'bg-secondary bg-lighten-5': step.step > active }"
              class="d-flex justify-content-center align-items-center rounded-lg shadow mr-1"
              :style="`width: 35px; height: 35px;${step.step > active ? 'opacity: .5;' : ''}`"
            >
              <feather-icon
                :icon="step.icon"
                :class="{ 'text-white': step.step === active, 'text-primary': step.step < active }"
                size="18"
              />
            </div>
            <div
              :class="{ 'text-primary': step.step === active, 'text-secondary text-lighten-5': step.step !== active }"
              class="h6 mb-0 font-weight-bolder"
              style="user-select: none;"
            >
              {{ step.title }}
            </div>
          </div>
        </div>
        <Products v-if="active === 0" />
        <div v-if="active === 1">
          <Address @next="active += 1" />
        </div>
        <div v-if="active === 2">
          <Payment @next="merci" @reset="newOrder"/>
        </div>
        <div v-if="active === 3">
          <Thanks :order="order" @reset="newOrder" />
        </div>
      </b-col>
      <b-col
        xs="12"
        md="12"
        lg="4"
        class="mb-3"
      >
        <Cart
          :active="active"
          @next="active += 1"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Products from '@/components/encaissement/Products.vue'
import Cart from '@/components/encaissement/Cart/index.vue'
import Address from '@/components/encaissement/Address.vue'
import Payment from '@/components/encaissement/Payment.vue'
import Thanks from '@/components/encaissement/Thanks.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Cart,
    Products,
    Address,
    Payment,
    Thanks,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  data() {
    return {
      active: 0,
      order: null,
      steps: [{
        title: 'Panier',
        step: 0,
        icon: 'SearchIcon',
      }, {
        title: 'Adresse',
        step: 1,
        icon: 'HomeIcon',
      }, {
        title: 'Paiement',
        step: 2,
        icon: 'CreditCardIcon',
      }],
    }
  },
  computed: {
    ...mapGetters('cart', ['items']),
  },
  async created() {
    // this.initialize()
    if (this.$route?.query?.mollie) {
      this.reset()
      this.active = 3
      this.$route.query = null
    } else if (this.$route?.query?.order) {
      const order = await this.get(this.$route.query.order)
      if (order && this.$route?.query?.active) {
        // if (order?.line_items?.length > 0 && this.items?.length === 0) {
        //   console.log('line items : ', order.line_items)
        //   order.line_items.map(item => this.add(item))
        // }
        this.order = { number: this.$route.query.order }
        this.active = Number(this.$route?.query?.active)
      } else {
        this.active = 2
      }
    } else if (this.$route?.query?.error === 'true') {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Une erreur de paiement est survenue ( peut-être lié à votre plafond de carte ) ',
          icon: 'EditIcon',
          variant: 'danger',
          timeout: 10000,
        },
      })
      this.active = 2
    }
  },
  methods: {
    ...mapActions('order', ['get']),
    ...mapActions('cart', ['add', 'initialize', 'reset']),
    changeStep(key) {
      if (key < this.active) {
        this.active = key
      }
    },
    newOrder() {
      this.order = null
      window.location = '/encaissement'
    },
    merci(order) {
      this.order = order
      this.active += 1
    },
  },
}
</script>
